import React, { useState } from "react"
import { useForm } from "react-hook-form"

const ContactForm = props => {
  const { contactState, handleOnNextPage } = props
  const { selectedType, step } = contactState
  const [checkedState, setCheckedState] = useState(false)
  const handleOnChecked = () => {
    setCheckedState(!checkedState)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm()

  const onSubmit = data => {
    handleOnNextPage(data)
  }

  if (selectedType === "v1" && step === 1) {
    return (
      <div className="contact-service">
        <h3>店舗サービスに関するお問い合わせ</h3>
        <p className="note">
          <span className="req">*</span>は、必須事項です
        </p>
        <form className="edit-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="filed-wrapper">
            <label className="form-label">
              <span className="form-label-text">
                ご意見・ご質問<span className="req">*</span>
              </span>
              {errors.message && errors.message.type === "required" && (
                <span className="e-msg">ご意見、ご質問を入力してください</span>
              )}
            </label>
            <textarea
              name="message"
              cols={40}
              rows={10}
              defaultValue=""
              {...register("message", { required: true })}
            />
          </div>
          <div className="filed-wrapper">
            <label className="form-label" htmlFor="shopName">
              <span className="form-label-text">
                店舗名<span className="req">*</span>
              </span>
              {errors.shopName && errors.shopName.type === "required" && (
                <span className="e-msg">店舗名を入力してください</span>
              )}
            </label>
            <input
              id="shopName"
              type="text"
              name="shopName"
              size={40}
              defaultValue=""
              {...register("shopName", { required: true })}
            />
          </div>
          <div className="filed-wrapper">
            <h4 className="form-label-text">
              <span>
                お名前（漢字）<span className="req">*</span>
              </span>
            </h4>
            <ul className="name-field">
              <li>
                <label className="form-label" htmlFor="lastName">
                  <span>姓</span>
                  {errors.lastName && (
                    <span className="e-msg">姓を入力してください</span>
                  )}
                </label>
                <input
                  id="lastName"
                  type="text"
                  name="lastName"
                  size={40}
                  defaultValue=""
                  {...register("lastName", { required: true })}
                />
              </li>
              <li>
                <label className="form-label" htmlFor="firstName">
                  <span>名</span>
                  {errors.firstName && (
                    <span className="e-msg">名を入力してください</span>
                  )}
                </label>
                <input
                  id="firstName"
                  type="text"
                  name="firstName"
                  size={40}
                  defaultValue=""
                  {...register("firstName", { required: true })}
                />
              </li>
            </ul>
          </div>
          <div className="filed-wrapper">
            <h4 className="form-label-text">お名前（カナ）</h4>
            <ul className="name-field">
              <li>
                <label className="form-label" htmlFor="lastNameKana">
                  <span>セイ</span>
                </label>
                <input
                  id="lastNameKana"
                  type="text"
                  name="lastNameKana"
                  size={40}
                  defaultValue=""
                  {...register("lastNameLana")}
                />
              </li>
              <li>
                <label className="form-label" htmlFor="firstNameKana">
                  <span>メイ</span>
                </label>
                <input
                  id="firstNameKana"
                  type="text"
                  name="firstNameKana"
                  size={40}
                  defaultValue=""
                  {...register("firstNameLana")}
                />
              </li>
            </ul>
          </div>
          <div className="filed-wrapper">
            <label className="form-label" htmlFor="email">
              <span className="form-label-text">
                メールアドレス<span className="req">*</span>
              </span>
              {errors.email && errors.email.type === "required" && (
                <span className="e-msg">メールアドレスを入力してください</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span className="e-msg">{errors.email.message}</span>
              )}
            </label>
            <input
              id="email"
              type="text"
              name="email"
              size={40}
              defaultValue=""
              {...register("email", {
                required: true,
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "メールアドレスが正しくありません",
                },
              })}
            />
          </div>
          <div className="filed-wrapper">
            <label className="form-label" htmlFor="email-confirmation">
              <span className="form-label-text">
                メールアドレス（確認）<span className="req">*</span>
              </span>

              {errors.emailConfirmation &&
                errors.emailConfirmation.type === "required" && (
                  <span className="e-msg">
                    メールアドレス（確認）を入力してください
                  </span>
                )}
              {errors.emailConfirmation &&
                errors.emailConfirmation.type === "pattern" && (
                  <span className="e-msg">
                    {errors.emailConfirmation.message}
                  </span>
                )}
              {errors.emailConfirmation &&
                errors.emailConfirmation.type === "validate" && (
                  <span className="e-msg">
                    {errors.emailConfirmation.message}
                  </span>
                )}
            </label>
            <input
              id="email-confirmation"
              type="text"
              name="emailConfirmation"
              size={40}
              defaultValue=""
              {...register("emailConfirmation", {
                required: true,
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "メールアドレス（確認）が正しくありません",
                },
                validate: value => {
                  return (
                    value === getValues("email") ||
                    "メールアドレス（確認 ）が一致しません"
                  )
                },
              })}
            />
          </div>
          <div className="filed-wrapper">
            <label className="form-label" htmlFor="phone">
              <span className="form-label-text">お電話番号</span>
              {errors.phone && errors.phone.type === "pattern" && (
                <span className="e-msg">{errors.phone.message}</span>
              )}
            </label>
            <input
              id="phone"
              type="tel"
              name="phone"
              size={40}
              placeholder="ハイフンの入力は不要です"
              defaultValue=""
              {...register("phone", {
                pattern: {
                  value: /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/,
                  message: "お電話番号が正しくありません",
                },
              })}
            />
          </div>
          <div className="filed-wrapper">
            <h4 className="form-label-text">
              <span>
                個人情報の取り扱いへの同意確認
                <span className="req">*</span>
              </span>
            </h4>
          </div>

          <div className="privacyCheck">
            <p className="privacyText">
              <a href="/agreement" target="_blank" rel="noopener noreferrer">
                個人情報のお取り扱いに関する同意事項
                <svg className="svg-icon" viewBox="0 0 20 20">
                  <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z" />
                </svg>
              </a>
            </p>
            <label>
              <span className="check-box-wrapper">
                <input
                  type="checkbox"
                  className="privacy-check-button"
                  checked={checkedState}
                  onChange={() => handleOnChecked()}
                />
                <span />
              </span>
              個人情報のお取り扱いに関する同意事項を読み、内容に同意します。
            </label>
          </div>
          <button
            className={`sending-check-button ${
              checkedState ? "active" : "disabled"
            }`}
          >
            <span>送信確認</span>
          </button>
        </form>
      </div>
    )
  }
}

export default ContactForm
