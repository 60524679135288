import React from "react"
import { Link } from "gatsby"

const ContactComplete = props => {
  const { step, selectedType } = props
  if (selectedType === "v1" && step === 3) {
    window.scroll({ top: 0, behavior: "auto" })
    return (
      <div className="contact-service">
        <div className="finish-content">
          <div className="content-wrapper">
            <h3 className="e">送信が完了いたしました</h3>
            <p>お問い合わせいただき、ありがとうございました</p>
            <Link to="/">
              <span>TOP PAGE</span>
            </Link>
          </div>
        </div>
      </div>
    )
  }
  return ""
}

export default ContactComplete
