import React from "react"

const ContactSelect = props => {
  const { handleOnSelect, contactState } = props
  const { selectedType, step } = contactState
  if (selectedType === "v1" && step === 3) return ""
  return (
    <div className="select-contanct-menu">
      <p>お問い合わせ種別</p>
      <div className="select-wrapper">
        <select onChange={handleOnSelect}>
          <option value="" hidden>
            お問い合わせ種別を選択してください
          </option>
          <option value="v1">店舗サービスに関するお問い合わせ</option>
          <option value="v2">ランドリーアウトに関するお問い合わせ</option>
          <option value="v3">出店に関するお問い合わせ</option>
          <option value="v4">取材・撮影のご依頼など</option>
        </select>
      </div>
    </div>
  )
}

export default ContactSelect
