import React, { useState } from "react"
import axios from "axios"
import Layout from "components/layout"
import Seo from "components/seo"
import PageTitle from "components/pageTitle"
import ContactSelect from "components/contact/contactSelect"
import ContactOthers from "components/contact/contactOthers"
import ContactForm from "components/contact/contactForm"
import ContactConfirm from "components/contact/contactConfirm"
import ContactComplete from "components/contact/contactComplete"

import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const ContactPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [authMessage, setAuthMessage] = useState("")
  const [contactState, setContactState] = useState({
    selectedType: "",
    step: 1,
  })
  const [contactFormState, setContactFormState] = useState({
    message: "",
    shopName: "",
    lastName: "",
    firstName: "",
    lastNameKana: "",
    firstNameKana: "",
    email: "",
    phone: "",
  })
  const { selectedType, step } = contactState
  const handleOnSelect = e => {
    setContactState({
      ...contactState,
      selectedType: e.target.value,
    })
  }

  const handleChangeText = e => {
    const key = e.target.name
    setContactFormState({
      ...contactFormState,
      [key]: e.target.value,
    })
  }

  const handleOnNextPage = data => {
    setContactFormState({
      ...contactFormState,
      message: data.message,
      shopName: data.shopName,
      lastName: data.lastName,
      firstName: data.firstName,
      lastNameKana: data.lastNameKana ?? "",
      firstNameKana: data.firstNameKana ?? "",
      email: data.email,
      phone: data.phone ?? "",
    })
    setContactState({
      ...contactState,
      step: step + 1,
    })
  }
  const handleOnBackPage = () => {
    setContactState({
      ...contactState,
      step: step - 1,
    })
  }

  const sendMail = async processing => {
    const reCaptchaToken = await executeRecaptcha("contactPage")
    const baseUrl = process.env.STRAPI_URL
    const data = {
      message: contactFormState.message,
      shop_name: contactFormState.shopName,
      last_name: contactFormState.lastName,
      first_name: contactFormState.firstName,
      last_name_kana: contactFormState.lastNameKana,
      email: contactFormState.email,
      phone: contactFormState.phone,
      token: reCaptchaToken,
    }

    await axios
      .post(
        `${baseUrl}/api/contacts`,
        { data: data },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(res => {
        if (res.data?.data.attributes) {
          setContactState({
            ...contactState,
            step: step + 1,
          })
        }

        if (res.data.data?.errorCode === 401) {
          setAuthMessage(
            "認証エラーが発生しました。ページを更新し再度送信してください。"
          )
        }
      })
      .catch(err => {
        alert(`お問合せに送信に失敗しました。`)
      })
      .finally(res => {
        processing.current = false
      })
  }

  return (
    <Layout>
      <Seo title="お問い合わせ" />
      <div className="contact design-style">
        <div className="contents">
          <PageTitle title="Contact" subTitle="お問い合わせ" />

          <div className="contents-body">
            <ContactSelect
              handleOnSelect={handleOnSelect}
              contactState={contactState}
            />
            <ContactOthers selectedType={selectedType} />
            <ContactForm
              contactState={contactState}
              contactFormState={contactFormState}
              handleOnNextPage={handleOnNextPage}
              handleChangeText={handleChangeText}
            />
            <ContactConfirm
              contactState={contactState}
              contactFormState={contactFormState}
              authResult={authMessage}
              handleOnBackPage={handleOnBackPage}
              sendMail={sendMail}
            />
            <ContactComplete step={step} selectedType={selectedType} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
