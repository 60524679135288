import React from "react"

const PageTitle = props => {
  return (
    <div className="page-title fw-b tx-center">
      <h2 className="title e">{props.title}</h2>
      <p className="sub-title">{props.subTitle}</p>
    </div>
  )
}
export default PageTitle
