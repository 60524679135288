import React from "react"

const ContactOthers = props => {
  const { selectedType } = props
  if (selectedType === "v2") {
    return (
      <div className="contact-laundry-out">
        <h3>ランドリーアウトに関するお問い合わせ</h3>
        <ul>
          <li>
            <a
              href="https://laundry-out.jp/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              個人のお客さま
              <svg className="svg-icon" viewBox="0 0 20 20">
                <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z" />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://laundry-out.jp/biz/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              法人のお客さま
              <svg className="svg-icon" viewBox="0 0 20 20">
                <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    )
  }
  if (selectedType === "v3") {
    return (
      <div className="contact-store">
        <h3>出店に関するお問い合わせ</h3>
        <ul>
          <li>
            <a
              href="https://okulab.co.jp/contact-store"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちらへ
              <svg className="svg-icon" viewBox="0 0 20 20">
                <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    )
  }
  if (selectedType === "v4") {
    return (
      <div className="contact-opening">
        <h3>取材・撮影のご依頼など</h3>
        <ul>
          <li>
            <a
              href="https://okulab.co.jp/contact-pr"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちらへ
              <svg className="svg-icon" viewBox="0 0 20 20">
                <path d="M17.391,2.406H7.266c-0.232,0-0.422,0.19-0.422,0.422v3.797H3.047c-0.232,0-0.422,0.19-0.422,0.422v10.125c0,0.232,0.19,0.422,0.422,0.422h10.125c0.231,0,0.422-0.189,0.422-0.422v-3.797h3.797c0.232,0,0.422-0.19,0.422-0.422V2.828C17.812,2.596,17.623,2.406,17.391,2.406 M12.749,16.75h-9.28V7.469h3.375v5.484c0,0.231,0.19,0.422,0.422,0.422h5.483V16.75zM16.969,12.531H7.688V3.25h9.281V12.531z" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    )
  }
  return ""
}

export default ContactOthers
