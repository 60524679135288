import React, { useRef } from "react"

const ContactConfirm = React.memo(props => {
  const {
    contactState,
    contactFormState,
    handleOnBackPage,
    sendMail,
    authResult,
  } = props
  const { selectedType, step } = contactState
  const processing = useRef(false)
  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()

    if (processing.current) return
    processing.current = true
    sendMail(processing)
  }
  if (selectedType === "v1" && step === 2) {
    window.scroll({ top: 0, behavior: "auto" })
    return (
      <div className="contact-service">
        <h3 className="">店舗サービスに関するお問い合わせ</h3>
        <p className="note">
          <span className="req">*</span>は、必須事項です
        </p>
        <form onSubmit={handleSubmit} className="check-form">
          <dl className="check-list">
            <div>
              <dt className="fw-b">
                ご意見、ご質問<span className="req">*</span>
              </dt>
              <dd className="checkText1">{contactFormState.message}</dd>
            </div>
            <div>
              <dt className="fw-b">
                店舗名<span className="req">*</span>
              </dt>
              <dd className="checkText2">{contactFormState.shopName}</dd>
            </div>
            <div>
              <dt className="fw-b">
                お名前（漢字）<span className="req">*</span>
              </dt>
              <dd className="checkText3">
                {contactFormState.lastName} {contactFormState.firstName}
              </dd>
            </div>
            <div>
              <dt className="fw-b">お名前（カナ）</dt>
              <dd className="checkText4">
                {contactFormState.lastNameKana} {contactFormState.firstNameKana}
              </dd>
            </div>
            <div>
              <dt className="fw-b">
                メールアドレス<span className="req">*</span>
              </dt>
              <dd className="checkText5">{contactFormState.email}</dd>
            </div>
            <div>
              <dt className="fw-b">お電話番号</dt>
              <dd className="checkText6">{contactFormState.phone}</dd>
            </div>
            <div>
              <p className="authError fw-b">{authResult}</p>
            </div>
          </dl>
          <div className="button-group">
            <button
              onClick={() => handleOnBackPage()}
              className="sending-back-button"
            >
              <span className="fw-n">戻る</span>
            </button>

            <button type="submit" className="sending-next-button">
              <span className="fw-n">送信する</span>
            </button>
          </div>
        </form>
      </div>
    )
  }
})

export default ContactConfirm
